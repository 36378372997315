
body {
  background-color: #111213;
  margin: 0; padding: 0; color: white;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px !important; line-height: 24px;
}

input {
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

select {
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

textarea {
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

a { text-decoration: none; color: white; }
a:hover { color: #00FFFF; }
.underline { text-decoration: underline; text-decoration-color: #00FFFF; }

::-webkit-scrollbar { width: 16px; }
::-webkit-scrollbar-track { background-color: transparent; }

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 0.618rem;
  border: 3px solid transparent;
  background-clip: content-box;
}

/* background-color: #a8bbbf; */
::-webkit-scrollbar-thumb:hover {
  background-color: #00FFFF;
}